<template>
	
   <div class="register">
   	  <div class="bg_img">
   	  	<div class="zhuce">
   	  		<div class="title">
   	  		     用户注册
   	  		</div>
			<div class="wrap-input100 validate-input m-b-23">
			  <span class="label-input100">手机号</span>
			  <input v-model="phones" class="input100" type="text" name="phones" placeholder="请输入手机号" autocomplete="off">
			  <span class="focus-input100" data-symbol=""></span>
			  <img class="icon_img" src="../assets/img/yonghu_ic.png" alt="">
			</div>
			<div class="wrap-input100 validate-input m-b-23">
			  <span class="label-input100">验证码</span>
			  <input class="input100" v-model="text_num"  type="text" placeholder="请输入验证码"  autocomplete="off">
			  <span class="focus-input100" data-symbol=""></span>
			  <img class="icon_img" src="../assets/img/suozi_ic.png" alt="">
			  <button class="send active" v-if='disabled==false'  @click="infosub" :disabled='disabled'>{{yanzhengma}}</button>
			  <button class="send active1" v-if='disabled==true' @click="infosub" :disabled='disabled'>{{yanzhengma}}</button>
			</div>
			<div class="wrap-input100 validate-input m-b-23">
			  <span class="label-input100">密码</span>
			  <input class="input100" v-model="pwd1" @blur="codes1" :disabled="codes" type="password" placeholder="密码必须是8-20位字母、数字或字符,至少包含两种!" autocomplete="off">
			  <span class="focus-input100" data-symbol=""></span>
			  <img class="icon_img" src="../assets/img/suozi_ic.png" alt="">
	
			</div>
			<div class="wrap-input100 validate-input m-b-23">
			  <span class="label-input100">重复密码</span>
			  <input class="input100"  v-model="pwd2" @blur="codes2" :disabled="codes" type="password" placeholder="请输入密码" autocomplete="off">
			  <span class="focus-input100" data-symbol=""></span>
			  <img class="icon_img" src="../assets/img/suozi_ic.png" alt="">
			</div>
			<div class="regbtn">
				<button class="regbtns" @click="register"  type="button">注册</button>
				
			</div>
			<div class="gologin" @click="tologin">
				返回登录
			</div>
   	  	</div>
   	  </div>
   </div>
</template>

<script>
import md5 from 'js-md5';
import Axios from "axios";
export default {
	   metaInfo(){
     return{
        title: '用户注册',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
        disabled:false,
		phones:'',
		four:'',
		yanzhengma:'发送验证码',
		timeFun:'',
		codes:false,
		pwd1:'',
		pwd2:'',
		timer:6,
		text_num:''
    };
  },
  created() {


  },
  methods: {
     infosub(){
		 let num=this.phones;
		 var that=this;
		 var phone = /^1[3456789]\d{9}$/;
		 if(!num){	
			that.$message.error('电话号不能为空');
			//  layer.msg("电话号不能为空"); 
			 return false
		 }else if(num.length!=11){
			 that.$message.error('请输入11位正确的电话号码');
			//  layer.msg("请输入11位正确的电话号码"); 
			 return false
		 }else if(phone.test(num)){
		    this.four=num.substr(num.length-4)
			console.log(this.four);
			 Axios({
			   method: "post",
			   url: "http://weixin.qudong.com/api/user/security_code",
			  params: {
			     phone_num:this.phones,
			     captcha:md5(this.four),
			   },
			 }).then(res=>{
				 console.log(res);
				 if(res.data.code==200){
					 this.disabled=true
					 	this.yanzhengma=this.timer+"s";
					 var timeFun=setInterval(function(){
						 console.log(that.timer);
						 that.timer--
						 if(this.timer==0){
							 this.yanzhengma="发送验证码";
							 clearInterval(timeFun)
							 this.disabled=false
							 this.timer=6
						 }
					 }.bind(this),1000)			 
				 }
			 })
			 return true
		 }
	 },
	 tologin(){
		 this.$router.push('/login')
	 },
	codes1(){
		let tel= /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{6,30}/
		if(!tel.test(this.pwd1)){	
			this.$message.error('密码必须是8-20位字母、数字或字符,至少包含两种!');		
			// layer.msg("密码必须是8-20位字母、数字或字符,至少包含两种!"); 
			this.pwd1=''
			return false
		}else{
			return true
		}
		
	},
	codes2(){
		if(this.pwd1!=this.pwd2){
			this.$message.error('两次密码不一样，请重新输入');		
			// layer.msg("两次密码不一样，请重新输入");
			this.pwd2=''
			return false
		}else{
			return true
		}
	},
	register(){
			Axios({
			  method: "post",
			  url: "https://weixin.qudong.com/api/user/register",
			 params: {
			    phone:this.phones,
			    password:this.pwd1,
				re_password:this.pwd2,
				code:this.text_num
			  },
			}).then(res=>{
				console.log(res);
				 if(res.data.code==200){
					 this.$message.success('注册成功');
					// layer.msg("注册成功");
					this.$router.push('/login')
				}
				else{
				this.$message.error(res.data.msg);	
				//   layer.msg(res.data.msg);
				}
			})	
	}
  }
};
</script>

<style scoped>
.bg_img{
	width: 100%;
	background-image: url("https://club.qudong.com/static/index/login/images/bg-01.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
	min-height: 100vh;
	min-width: 100%;
}
.zhuce{
	width:32% ;
	height: 77%;
	background-color: white;
	border-radius: 20px;
	position: absolute;
	margin: auto;
	top:0;
	left:0;
	right:0;
	bottom:0;
	min-width: 500px;
}
.zhuce .title{
	width: 100%;
	height: 50px;
	font-size: 39px;
	color: #333333;
    text-align: center;
	line-height: 50px;
	margin-top: 65px;
}
.wrap-input100 {
  width: 80%;
  margin-top: 25px;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  margin-left: 10%;
}
.send{
	width: 100px;
	height: 30px;
	
	    position: absolute;
	    right: 10px;
	    bottom: 10px;
		outline: none;
	color: white;

}
.active{
	background-color: seagreen;
	border:none;
}
.active1{
	background-color: #7B7B7B;
}
.input100 {
  font-family: "Microsoft Yahei";
  font-size: 16px;
  color: #333333;
  border: none;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 55px;
  background: transparent;
      padding: 0 7px 0 40px;
    
}
.wrap-input100 .icon_img{
	display: block;
	    border: 0;
	    position: absolute;
	    left: 9px;
	    top: 40px;
}
/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #adadad;
  font-size: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #7f7f7f;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #a64bf4;
}
.has-val.input100 + .focus-input100::after {
  color: #a64bf4;
}
.regbtn{
	width: 80%;
	height:60px;
	margin: 10px auto;
}
.regbtn .regbtns{
	width: 100%;
	height: 80%;
	border-radius: 50px;
	background: linear-gradient(to right, #00dbde, #aa55ff, #fc00ff);
	cursor: pointer;
	border:none;
}
.gologin{
	width: 100%;
	height: 20px;
	font-size: 14px;
    text-align: center;
	line-height: 20px;
	cursor: pointer;
}
</style>